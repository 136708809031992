<template>
  <div class="order-details">
    <div class="btn" @click="goBack">返回上一页</div>
    <div class="order-heard">
      <div class="heard-left">
        <div class="order-status">
          <img :src="imgList" alt="" />
          <span>{{ statusText[orderDetails.state] }}</span>
        </div>
        <div class="serverTime" v-if="orderDetails.state == 0">
          剩 <span class="s-t-t-num">{{ surplus }}</span>
          订单自动取消
        </div>
      </div>
      <div class="heard-btns">
        <span
          v-if="orderDetails.state == 0"
          class="unpaid"
          @click="goPay(orderDetails)"
          >去支付</span
        >
        <span v-if="orderDetails.state == 0" @click="cancelOrder(orderDetails)"
          >取消订单</span
        >
        <span
          v-if="orderDetails.state == 1 || orderDetails.state == 2"
          @click.stop="goRefund(orderDetails)"
          class="refund"
          >申请退款</span
        >
        <span v-if="orderDetails.state == 3">退款中</span>
        <div v-if="orderDetails.state == 4">
          <span>退款成功</span>
        </div>
        <span v-if="orderDetails.state == 5">驳回退款</span>
        <div v-if="orderDetails.state == 6">
          <span>已取消</span>
        </div>
      </div>
    </div>
    <div class="cut-off-rule"></div>
    <div class="goodsInfo">
      <img :src="orderDetails.productImg || ''" alt="" />
      <div class="info">
        <p class="courseName">{{ orderDetails.productTitle }}</p>
        <p class="settlementAmount">${{ orderDetails.amount }}</p>
      </div>
    </div>

    <div class="orderInfo">
      <div class="info-cell">
        <div class="title">订单金额</div>
        <div class="price">¥{{ orderDetails.amount }}</div>
      </div>
      <div
        class="info-cell"
        v-if="orderDetails.state != 0 && orderDetails.state != 6"
      >
        <div class="title">实付金额</div>
        <div class="price">${{ orderDetails.amount }}</div>
      </div>
    </div>

    <div class="cut-off-rule"></div>
    <div class="orderInfo bottom-box">
      <div class="info-cell">
        <div class="title">订单编号：</div>
        <div class="info-cell-right">{{ orderDetails.orderNumber }}</div>
      </div>
      <div class="info-cell">
        <div class="title">下单时间：</div>
        <div class="info-cell-right">
          {{ orderDetails.createTime }}
        </div>
      </div>
      <div class="info-cell" v-if="orderDetails.state == 2">
        <div class="title">收款时间：</div>
        <div class="info-cell-right">
          {{ orderDetails.payTime }}
        </div>
      </div>
    </div>
    <Pay :price="orderDetails.amount" :payUrl="payUrl" ref="paybox" />
  </div>
</template>

<script>
import {
  getOrderDertails,
  cancelOrder,
  orderRefund,
} from "@/api/pay.js";
import Pay from "@/components/addPay/pay.vue";
import { Message } from "element-ui";
export default {
  props: {
    orderNumber: {
      type: String,
      default: "",
    },
    payUrl:{
      type: String,
      default: "",
    }
  },
  components: { Pay },
  data() {
    return {
      orderDetails: {},
      statusText: {
        0: "待付款",
        1: "预收款",
        2: "已收款",
        3: "退款中",
        4: "退款完成",
        5: "拒绝退款",
        6: "取消",
      },
      imgList: require("@/assets/mine/top_icon.png"),
      surplus: "",
      startTime: "",
      endTime: "",
    };
  },

  created() {
    this.getOrderIno(this.orderNumber);
  },

  methods: {
    goBack() {
      this.$emit("goBack");
    },
    getOrderIno(id) {
      getOrderDertails({
        orderNumber: id,
      }).then((res) => {
        if (res.code == 0) {
          this.orderDetails = res.data;
          this.startTime = this.orderDetails.createTime;
          this.endTime = this.orderDetails.payExpiredTime;
          // 开启倒计时
          if (this.orderDetails.state == 0) {
            this.setSurplus(this.startTime, this.endTime);
          }
        }
      });
    },
    setSurplus(startTime, endTime) {
      let app = this;
      let transedPreTime = endTime.replace(/-/g, "/"); //这里转化时间格式为以/分隔形式
      const timer = setInterval(() => {
        let nowTime = new Date().getTime();
        let preTime = new Date(transedPreTime).getTime();
        console.log(preTime, preTime);
        if (preTime - nowTime > 0) {
          let time = (preTime - nowTime) / 1000;
          // let day = parseInt(time / (60 * 60 * 24));
          let hou = parseInt((time % (60 * 60 * 24)) / 3600);
          let min = parseInt(((time % (60 * 60 * 24)) % 3600) / 60);
          let sec = parseInt(((time % (60 * 60 * 24)) % 3600) % 60);
          let obj = {
            // day: day < 10 ? '0' + day : day,
            hou: hou < 10 ? "0" + hou : hou,
            min: min < 10 ? "0" + min : min,
            sec: sec < 10 ? "0" + sec : sec,
          };
          // obj.day + ':' +
          this.surplus = obj.hou + ":" + obj.min + ":" + obj.sec;
        } else {
          clearInterval(timer);
          cancelOrder({
            orderNumber: app.details.orderNumber,
          }).then((res) => {
            if (res.code === 0) {
              this.$message.info("订单已自动取消");
            }
          });
          app.getOrderIno(app.details.orderNumber);
        }
      }, 1000);
    },

    // 申请退款
    goRefund(info) {
      this.filters
        .messageBox("申请退款")
        .then(() => {
          //
          orderRefund({
            orderNumber: info.orderNumber,
          }).then((res) => {
            if (res.code == 0) {
              Message({
                type: "success",
                message: "退款申请提交成功，请耐心等待后台处理",
              });
              this.getOrderIno(info.orderNumber);
            }
          });
        })
        .catch(() => {});
    },
    // 取消订单
    cancelOrder(item) {
      this.filters
        .messageBox("取消订单")
        .then(() => {
          //
          cancelOrder({
            orderNumber: item.orderNumber,
          }).then((res) => {
            if (res.code == 0) {
              Message({
                type: "success",
                message: "取消成功",
              });
              this.getOrderIno(item.orderNumber);
            }
          });
        })
        .catch(() => {});
    },
    // 去支付
    goPay(item) {
      this.$refs.paybox.dialogVisible1 = true;
      // addPay({
      //   orderNumber: orderNo,
      //   pageType: 2,
      //   clientType: 3,
      //   feeType:'HKD',
      //   returnUrl:
      //     "https:///www.swa.orq.nk/#/pay-success?type=" +
      //     this.$route.type +
      //     "&orderNumber=" +
      //     orderNo +
      //     "&price=" +
      //     item.amount +
      //     "&isppf=1",
      // }).then((req) => {
      //   if (req) {
      //     this.payUrl = req.pay_apptrade;
      //     window.location.href = this.payUrl;
      //     //   this.$refs.paybox.dialogVisible1 = true;
      //   }
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.btn {
  text-align: left;
  margin: 10px 0;
  padding: 8px 0px;
  background: #409eff;
  color: #fff;
  border-radius: 4px;
  width: 80px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}
.order-details {
  .order-heard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #ffffff;
    border-radius: 4px;
    padding: 0 20px;
    box-sizing: border-box;
    .heard-left {
      display: flex;
      align-items: center;
    }
    .order-status {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
      }
    }
    .heard-btns {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      .unpaid {
        width: 116px;
        height: 40px;
        background: #fb3a4e;
        color: #fff;
      }
      .cancelRefund {
        width: 116px;
        height: 40px;
        border: 1px solid #cccccc;
        color: #999999;
      }
      .refund {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 28px;
        background: #fb3a4e;
        border-radius: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .goodsInfo {
    display: flex;
    align-items: center;
    margin: 0 25px;
    padding: 25px 0;
    border-bottom: 1px solid #eee;
    img {
      display: inline-block;
      width: 133px;
      height: 100px;
      border-radius: 4px;
      overflow: hidden;
      margin-right: 25px;
    }
    .info {
      .courseName {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-bottom: 17px;
      }
      .settlementAmount {
        font-size: 16px;
        font-family: DIN;
        font-weight: 500;
        color: #fb3a4e;
      }
    }
  }
  .orderInfo {
    .info-cell {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 25px;
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
      .price {
        font-size: 16px;
        font-family: DIN;
        font-weight: 500;
        color: #fb3a4e;
      }
      &-right {
        flex: 1;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        text-align: right;
      }
    }
  }
  .bottom-box {
    .title {
      color: #666 !important;
    }
  }
  .cut-off-rule {
    width: 100%;
    height: 8px;
    background-color: #f8f8f8;
  }
  .contact-box {
    .contact {
      display: flex;
      align-items: center;
      padding: 30px;
      img {
        display: inline-block;
        width: 150px;
        height: 150px;
      }
      .contact-right {
        padding-left: 20px;
        p {
          &:nth-child(1) {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          &:nth-child(2) {
            margin-top: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
  }
}
.serverTime {
  padding-left: 20px;
  .s-t-t-num {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fb3a4e;
  }
}
</style>
