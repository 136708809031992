import axios from "./index.js";

// export function getOrderList(params) {}

export function getInteTotal() {
  return axios.service({
    url: "/app/integral/total",
    method: "get",
  });
}

// 获取积分列表
export function getInteList(params) {
  return axios.service({
    url: "/app/integral/page",
    method: "get",
    params,
  });
}

// 上传用户图像
export function setMyInfo(data) {
  return axios.service({
    url: "/sys/pc/auth/update/user",
    method: "post",
    data: data,
  });
}
