<!--
 * @Author: your name
 * @Date: 2021-06-18 14:32:17
 * @LastEditTime: 2021-10-22 15:55:36
 * @LastEditors: Please set LastEditors
 * @Description: 订单卡片
 * @FilePath: \study-pc\components\mine\order-car.vue
-->
<template>
  <div>
    <div class="order-car">
      <!-- <nuxt-link :to='"/mine/order-details?orderNo=" + item.orderNo' >  -->
      <div class="order-title" @click="goDetail">
        <div class="title-left">
          <div class="order-title-time">
            {{ item.createTime }}
          </div>
          <div class="order-title-number">订单编号：{{ item.orderNumber }}</div>
        </div>
        <!-- <div class="serverTime" v-if="item.status == 1">
        剩 <span class="s-t-t-num">{{ filterNum(surplus.lefth) }}</span
        >: <span class="s-t-t-num">{{ filterNum(surplus.leftm) }}</span
        >:
        <span class="s-t-t-num">{{ filterNum(surplus.lefts) }}</span>
        订单自动取消
      </div> -->
      </div>
      <div class="order-content">
        <img :src="item.productImg" class="order-content-item" alt="" @click="gotoPageDetails"/>
        <div class="order-content-item" @click="gotoPageDetails">{{ item.productTitle }}</div>
        <div class="order-content-item">${{ item.amount }}</div>
        <div class="status-box">
          <div class="orderStatus">{{ activeList[item.state] }}</div>
          <div class="goDetail">查看详情</div>
        </div>
        <div class="btns">
          <div v-if="item.state == 0">
            <div class="goPay" @click.stop="goPay(item)">去支付</div>
            <div class="cancelOrder" @click.stop="cancelOrder(item)">
              取消订单
            </div>
          </div>
          <div v-else-if="item.state == 4">
            <span>退款成功</span>
          </div>
          <div v-else-if="item.state == 3">
            <span>退款中</span>
          </div>
          <div v-else>
            <span v-if="item.state == 6">已取消</span>
            <span
              v-if="item.state == 1 || item.status == 2 || item.status == 5"
              @click.stop="goRefund(item)"
              class="refund"
              >申请退款</span
            >
          </div>
        </div>
      </div>
    </div>
    <Pay :price="amount" :payUrl="payUrl" ref="paybox" />
  </div>
</template>
<script>
import { Message } from "element-ui";
import Pay from "@/components/addPay/pay.vue";
import { cancelOrder, orderRefund } from "@/api/pay.js";
export default {
  components: { Pay },
  data() {
    return {
      activeList: [
        "待付款",
        "待收款",
        "已收款",
        "退款中",
        "退款完成",
        "拒绝退款",
        "取消",
      ],
      startTime: 1617947995000,
      endTime: 1617948000000,
      surplus: {},
      timer: null,
      payUrl: "",
      amount:0
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  created() {
    if (this.item.status == 1) {
      this.setTime();
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setTime() {
      this.startTime = Number(this.item.serverTime) || 0;
      this.endTime = Number(this.item.expirationTime) || 0;
      // 开启倒计时
      let that = this;
      this.filters.setSurplus(this, function () {
        that.item.status = 3;
      });
    },
    filterNum(num) {
      return num > 9 ? num : "0" + num;
    },
    cancelRefund(item) {
      this.$emit("cancelRefund", item);
    },
    goDetail() {
      this.$emit("orderDetais", {
        orderNumber:this.item.orderNumber,url:this.item.payUrl
      });
    },
    gotoPageDetails(){
        this.$router.push({
          path:'free',
          query:{
            type:this.item.type,
            id:this.item.type == 1 ? this.item.activityId:this.item.classId
          }
        })
    },
    // 去支付
    goPay() {
      console.log(this.item)
      this.payUrl = this.item.payUrl
      this.amount = this.item.amount
      setTimeout(()=>{
        this.$refs.paybox.dialogVisible1 = true;
      },200)
      // 
    },

    goRefund(item) {
      this.filters
        .messageBox("申请退款")
        .then(() => {
          //
          orderRefund({
            orderNumber: item.orderNumber,
          }).then((res) => {
            if (res.code == 0) {
              Message({
                type: "success",
                message: "退款申请提交成功，请耐心等待后台处理",
              });
              this.$emit("goRefund", item);
            }
          });
        })
        .catch(() => {
          // Message({
          //   type: "info",
          //   message: "已取消",
          // });
        });
    },
    // 取消订单
    cancelOrder(item) {
      this.filters
        .messageBox("取消订单")
        .then(() => {
          //
          cancelOrder({
            orderNumber: item.orderNumber,
          }).then((res) => {
            if (res.code == 0) {
              Message({
                type: "success",
                message: "取消成功",
              });
              this.$emit("cancelOrder", item);
            }
          });
        })
        .catch(() => {
          // Message({
          //   type: "info",
          //   message: "已取消",
          // });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.order-car {
  width: 880px;
  height: 190px;
  border: 1px solid #f6f7f8;
  margin-top: 30px;
  cursor: pointer;
  a {
    width: 100%;
  }
  .order-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 880px;
    height: 30px;
    background: #f6f7f8;
    padding: 0 20px;
    box-sizing: border-box;
    .title-left {
      display: flex;
    }
    .serverTime {
      .s-t-t-num {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fb3a4e;
      }
    }
    &-time {
      //   width: 120px;
      color: #666666;
      font-size: 14px;
      margin-right: 50px;
    }
    &-number {
      color: #666666;
      font-size: 14px;
    }
  }
  .order-content {
    display: flex;
    align-items: center;
    height: 160px;
    box-sizing: border-box;
    padding: 0 20px;
    &-item {
      &:nth-child(1) {
        width: 168px;
        height: 100px;
        border-radius: 4px;
        overflow: hidden;
      }
      &:nth-child(2) {
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        padding-left: 20px;
        color: #333333;
        font-size: 16px;
      }
      &:nth-child(3) {
        width: 110px;
        color: #fb3a4e;
        font-size: 16px;
      }
      &:nth-child(4) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 30px;
        color: #333333;
        font-size: 14px;
        margin-right: 13px;
      }
      &:nth-child(5) {
        color: #999999;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .status-box {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      .orderStatus {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fb3a4e;
        line-height: 30px;
      }
      .goDetail {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
    }
    .btns {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 80px;
      .goPay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 28px;
        background: #fb3a4e;
        border-radius: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
      .contactBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 28px;
        background: rgba(251, 58, 78, 0.1);
        border-radius: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fb3a4e;
      }
      .cancelOrder {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 30px;
      }
      .refund {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 28px;
        background: #fb3a4e;
        border-radius: 14px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .present {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 30px;
      border: 1px solid #fb3a4e;
      border-radius: 4px;
      color: #fb3a4e;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
</style>
