<!--
 * @Author: your name
 * @Date: 2021-06-24 20:07:41
 * @LastEditTime: 2021-10-26 16:04:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\pages\mine\index\myorder.vue
-->
<template>
  <div class="update">
    <div class="nav">
      <span>编辑资料</span>
    </div>
    <el-form :model="addForm" ref="addForm" class="form-box" label-width="70px">
      <el-form-item prop="avatar" label="头像：">
        <div class="avater-item">
          <img
            :src="addForm.avatar ? addForm.avatar : headImg"
            class="avatar"
          />
          <!-- :http-request="upload" -->
          <el-upload
            action="https://www.swa.org.hk/api/pc/sys/file/upload"
            class="upload"
            :show-file-list="false"
            :limit="1"
            :headers="headers"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            >修改头像</el-upload
          >
        </div>
      </el-form-item>
      <el-form-item prop="realName" label="昵称：">
        <el-input
          placeholder="请输入姓名"
          v-model="addForm.realName"
          class="from-input"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="phone" label="手机号：">
        <el-input
          disabled
          v-model="addForm.mobile"
          class="from-input from-input-login"
        >
        </el-input>
      </el-form-item>

      <el-form-item prop="gender" label="性别：">
        <el-select v-model="addForm.gender" placeholder="请选择性别">
          <el-option label="男" value="0">男</el-option>
          <el-option label="女" value="1">女</el-option>
        </el-select>
      </el-form-item>
      <div @click="save">
        <el-button :loading="isClicklType" class="login-from-submit"
          >完成</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import { setMyInfo } from "@/api/mine";
import { getUserInfo } from "@/api/login.js";
export default {
  data() {
    return {
      isClicklType: false,
      headImg: require("@/assets/mine/headimg.png"),
      addForm: {
        avatar: "",
        mobile: "",
        realName: "",
        // emial: "",
        gender: "0",
      },
      headers: {
        Authorization: localStorage.getItem("loginToken"),
      },
    };
  },
  created() {
    console.log(this.$store.state.userInfo);
    this.addForm = {
      avatar: this.$store.state.userInfo.avatar,
      mobile: this.$store.state.userInfo.mobile,
      realName: this.$store.state.userInfo.realName,
      // emial: "",
      gender: this.$store.state.userInfo.gender
        ? this.$store.state.userInfo.gender.toString()
        : "0",
    };
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 3MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.addForm.avatar = res.data.url;
    },
    save() {
      this.isClicklType = true;
      const data = Object.assign(this.addForm, {
        gender: Number(this.addForm.gender),
      });
      setMyInfo(data).then((res) => {
        if (res) {
          getUserInfo().then((req) => {
            if (req.code === 0) {
              localStorage.setItem("userInfo", JSON.stringify(req.data));
              this.$store.commit("setUserInfo", req.data);
            }
          });
          this.$message.success("信息修改成功");
        }
        this.isClicklType = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f6f7f8;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  position: relative;
  margin-bottom: 43px;
  span::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 2px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px solid #f5394c;
    bottom: 0;
  }
}
.form-box {
  width: 500px;
  //   margin: 0 auto;
  margin-left: 20px;
  text-align: left;
  .el-select {
    width: 100%;
  }
  .login-from-submit {
    width: 120px;
    height: 40px;
    background: #f5394c;
    border-radius: 4px 4px 4px 4px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    border: none;
    margin-left: 70px;
    margin-top: 15px;
  }
  /deep/.el-input__inner {
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    line-height: 40px;
    background: #fff !important;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #cccccc;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 20px;
    color: #444444 !important;
  }
  /deep/.from-input-login .el-input__inner:first-child {
    // text-indent: 50px !important;
    // padding-left: 80px;
  }
  //   /deep/.el-input__inner::placeholder {
  //     color: #a34a0f !important;
  //   }
  //   /deep/.el-dropdown-code,
  //   /deep/.el-icon-arrow-down {
  //     color: #a34a0f !important;
  //   }
}
.avatar {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}
.avater-item {
  position: relative;
  .upload {
    position: absolute;
    bottom: 15px;
    width: 130px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    background: #eeeeee;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
  }
}
</style>
