<!--
 * @Author: your name
 * @Date: 2021-06-24 20:07:41
 * @LastEditTime: 2023-04-03 20:57:50
 * @LastEditors: yao_wb
 * @Description: In User Settings Edit
 * @FilePath: \fl-pc\src\views\mine\myorder.vue
-->
<template>
  <div>
    <Nav :navList="navList" @navClick="navClick"></Nav>
    <div>
      <!-- 
        @goContact="goContact"
       
        @goRefund="goRefund"
        @cancelRefund="cancelRefund" -->
      <OrderCar
        v-for="item in orderList"
        :key="item.orderNumber"
        :item="item"
        @cancelOrder="cancelOrder"
        @goRefund="goRefund"
        @orderDetais="orderDetais"
      ></OrderCar>
      <Pagination
        v-show="query.total > 0"
        :total="query.total"
        :page="query.page"
        :limit="query.limit"
        @pagination="pagination"
      />
      <div class="mine-noda-box" v-if="orderList.length == 0">
        <NoData imgType="order"></NoData>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "./components/nav.vue";
import OrderCar from "./components/order-car.vue";
import NoData from "@/components/no-data.vue";
import Pagination from "@/components/pagination.vue";
import { getOrderList } from "@/api/pay.js";

export default {
  props: {
    isBacK: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Nav,
    OrderCar,
    NoData,
    Pagination,
  },
  data() {
    return {
      navList: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "待付款",
        },
        {
          id: 1,
          name: "已付款",
        },
        {
          id: 2,
          name: "退款",
        },
        {
          id: 6,
          name: "取消",
        },
      ],
      orderList: [],
      query: {
        limit: 10,
        page: 1,
        // type: 1,
        state: -1,
        total: 0,
      },
    };
  },
  watch: {
    isBacK: {
      handler(val) {
        if (val) {
          this.getOrderList();
        }
      },
      deep: true,
    },
  },
  created() {
    this.getOrderList();
  },
  methods: {
    navClick(id) {
      this.query = {
        limit: 10,
        page: 1,
        // type: 1,
        state: id,
        total: 0,
      };
      this.getOrderList();
    },
    getOrderList() {
      getOrderList(this.query).then((res) => {
        if (res.code === 0) {
          this.orderList = res.data.list;
          this.query.total = res.data.total;
        }
      });
    },

    pagination(page) {
      this.query.page = page.page;
      this.query.limit = page.limit;
      this.getOrderList();
    },

    cancelOrder() {
      this.getOrderList();
    },
    goRefund() {
      this.getOrderList();
    },
    orderDetais(data) {
      this.$emit("orderDetais", data);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-dialog__header {
  display: flex;
  align-items: center;
  .el-dialog__headerbtn {
    top: 10px !important;
    z-index: 9;
  }
}
/deep/.el-dialog__body {
  position: relative;
}
.dialog-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  position: absolute;
  top: -15px;
  &::before {
    content: " ";
    display: inline-block;
    width: 4px;
    height: 14px;
    background: #fb3c4f;
    margin-right: 5px;
  }
}
/deep/.el-form {
  .el-select {
    width: 100%;
  }
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .cancel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 32px;
    border: 1px solid #cccccc;
    border-radius: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 32px;
    background: #fb3c4f;
    border-radius: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: none;
  }
}
.submitSucceed {
  display: flex;
  align-items: center;
  flex-flow: column;
  width: 100%;
  img {
    display: inline-block;
    width: 288px;
    height: 156px;
    margin: 0 auto;
  }
  .sycceed {
    margin-top: 40px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
  .hint {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 30px;
    span {
      color: #fb3a4e;
      cursor: pointer;
    }
  }
  .backBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    width: 324px;
    height: 40px;
    background: #fb3a4e;
    border-radius: 20px;
    margin-top: 30px;
    cursor: pointer;
  }
}
.training-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  .hint-a {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .hint-b {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    padding: 10px 0;
  }
  .qrcode {
    width: 150px;
    height: 150px;
  }
}
</style>
