<!--
 * @Author: your name
 * @Date: 2021-06-24 20:07:41
 * @LastEditTime: 2021-10-26 16:04:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\pages\mine\index\myorder.vue
-->
<template>
  <div class="update">
    <div class="nav">
      <span>修改密码</span>
    </div>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="logonFrom"
      class="login-form-box"
    >
      <el-form-item prop="phone" label="手机号:">
        <areaCode @setCode="setCode" :phoneAreaCode="phoneAreaCode"></areaCode>
        <el-input
          placeholder="请输入手机号码"
          v-model="ruleForm.phone"
          class="login-from-phone"
        >
        </el-input>
      </el-form-item>

      <div class="login-from-send">
        <el-form-item prop="code" label="验证码:">
          <div style="display: flex">
            <el-input
              placeholder="请输入验证码"
              v-model="ruleForm.code"
              class="login-from-code"
            >
            </el-input>
            <div @click="sendSms" class="login-from-send-code">
              {{ state.codeText }}
            </div>
          </div>
        </el-form-item>
      </div>
      <el-form-item prop="password" label="密码:">
        <el-input
          placeholder="请输入密码"
          v-model="ruleForm.password"
          class="login-from-password"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" label="确认密码:">
        <el-input
          placeholder="请输入密码"
          v-model="ruleForm.newPass"
          class="login-from-password"
        >
        </el-input>
      </el-form-item>

      <div @click="submit" class="login-from-submit">完成</div>
    </el-form>
  </div>
</template>
<script>
import areaCode from "@/components/areaCode";
import { getCode, updaqtePass } from "@/api/login.js";
import throttle from "@/plugins/throttle";
export default {
  components: { areaCode },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        if (this.phoneAreaCode != "+86") {
          callback();
        } else {
          const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      captchaCode: "",
      ruleForm: {
        phone: "",
        password: "",
        newPass: "",
        code: "",
        key: "",
      },
      navList: ["修改密码"],
      rules: {
        phone: [{ required: true, validator: checkPhone, trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPass: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      state: {
        codeText: "获取验证码",
        time: 60,
      },
      phoneAreaCode: "+86",
    };
  },
  created() {},
  methods: {
    setCode(e) {
      this.phoneAreaCode = e;
    },
    submit() {
      let phone = "";
      this.$refs.logonFrom.validate((data) => {
        if (data) {
          if (this.phoneAreaCode == "+86") {
            phone = this.ruleForm.phone;
          } else {
            phone = this.phoneAreaCode + this.ruleForm.phone;
          }
          updaqtePass({
            newPassword: this.ruleForm.newPass,
            phone: phone,
            code: this.ruleForm.code,
          }).then((res) => {
            if (res.code == 0) {
              this.$message.sucsse("修改密码成功");
              this.ruleForm = {
                phone: "",
                password: "",
                newPass: "",
                code: "",
                key: "",
              };
              this.$refs.logonFrom.resetFields();
            }
          });
        }
      });
    },
    sendSms() {
      if (this.state.codeText != "获取验证码") return;
      if (this.phoneAreaCode == "+86") {
        if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.ruleForm.phone)) {
          this.$message.warning("请填写正确手机号码");
          return false;
        }
      }

      let data = {
        mobile: this.ruleForm.phone,
        area: this.phoneAreaCode,
      };
      getCode(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message.success("验证码发送成功");
          throttle(async () => {
            this.state.time = 60;
            const verification = setInterval(() => {
              this.state.codeText = this.state.time + "s重新获取";
              this.state.time--;
              if (this.state.time < 0) {
                clearInterval(verification);
                this.state.codeText = "获取验证码";
              }
            }, 1000);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.nav {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f6f7f8;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  position: relative;
  span::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 2px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px solid #f5394c;
    bottom: 0;
  }
}
.login-form-box {
  // width: 400px;
  margin-top: 30px;
  .el-form-item__content {
    display: flex;
  }
  .login-from-phone {
    background: #f5f8fa;
    /deep/.el-input__inner {
      text-indent: 70px;
    }
  }
  // .login-from-code {
  // 	width: 275px;
  // 	margin-right: 10px;
  // }
  /deep/.el-input__inner {
    // background: #f5f8fa;
    width: 400px;
  }
  /deep/.el-form-item__label {
    width: 100px !important;
  }
  .no-login {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .code {
    width: 116px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
  }
}
.login-from-submit {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  width: 120px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background: #f5394c;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 100px;
}
.el-form-item {
  display: flex;
}
.login-from-send {
  // margin: 18px 0 0 41px;
  display: flex;
  position: relative;
  &-code {
    cursor: pointer;
    position: absolute;
    right: 10px;
    z-index: 100;
    color: #f5394c;
  }
}
</style>
