<!--
 * @Author: your name
 * @Date: 2021-06-18 10:18:42
 * @LastEditTime: 2021-10-26 16:01:23
 * @LastEditors: Please set LastEditors
 * @Description: 我的页面
 * @FilePath: \study-pc\pages\mine\index.vue
-->
<template>
  <div>
    <Header :type="'-2'" />
    <div class="mine">
      <div class="mine-content">
        <div class="mine-sidebar">
          <div class="personage-info">
            <div class="headImg-box">
              <img
                :src="
                  $store.state.userInfo.avatar
                    ? $store.state.userInfo.avatar
                    : headImg
                "
                class="headImg"
                alt=""
              />
              <img
                class="vipIdent"
                v-if="$store.state.userInfo.isVip"
                src="@/assets/mine/vipIdent.png"
              />
              <img class="vipIdent" v-else src="@/assets/mine/novip.png" />
            </div>
            <div class="nickname" v-if="$store.state.userInfo">
              {{ $store.state.userInfo.username
              }}<i class="el-icon-edit" @click="activeIndex = 4"></i>
            </div>
            <div
              class="addVip"
              v-if="!$store.state.userInfo.isVip"
              @click="$refs.vipbox.dialogVisible = true"
            >
              加入会员
            </div>
            <div class="jftext" v-if="$store.state.userInfo">
              {{ myTotal }}积分
            </div>
          </div>
          <div class="sidebar-list">
            <div
              v-for="(item, index) in sidebarList"
              :key="index"
              :class="activeIndex == index ? 'sidebar-active' : ''"
              @click="goContent(index)"
            >
              <div class="img-box"></div>
              <i
                :class="
                  index === 0
                    ? 'el-icon-s-order'
                    : index === 1
                    ? 'el-icon-coin'
                    : 'el-icon-setting'
                "
              ></i>
              <span>{{ item }}</span>
            </div>
          </div>
        </div>

        <!-- youce  -->
        <div class="min-right">
          <MyOrder
            v-if="activeIndex === 0 && !isDetails"
            @orderDetais="orderDetais"
            :isBacK="isBacK"
          />
          <MyJF v-if="activeIndex === 1" />
          <!-- <AboutUs v-if="activeIndex === 2" /> -->
          <MySetting v-if="activeIndex === 2" />
          <MyInfo v-if="activeIndex === 4" />
          <orderdetails
            v-if="activeIndex === 0 && isDetails"
            :orderNumber="orderNumber"
            :payUrl="payUrl"
            @goBack="goBack"
          />
        </div>
      </div>
    </div>

    <VipDaiolog ref="vipbox"></VipDaiolog>
  </div>
</template>

<script>
// import CourseDialog from '@/components/getCourseDialog'
import { getUserInfo } from "@/api/login.js";
import Header from "@/components/header-one/index.vue";
import MyOrder from "./myorder.vue";
import MyJF from "./myJF.vue";
import MySetting from "./myStting.vue";
import MyInfo from "./myInfo.vue";
// import AboutUs from "./about-us.vue";
import VipDaiolog from "@/components/addPay/index.vue";
import { getInteTotal } from "@/api/mine.js";
import Orderdetails from "./orderdetails.vue";
export default {
  middleware: "auth",
  components: {
    Header,
    MyOrder,
    MyJF,
    MySetting,
    // AboutUs,
    VipDaiolog,
    MyInfo,
    Orderdetails,
    // CourseDialog,
  },
  data() {
    return {
      activeIndex: 0,
      sidebarList: ["我的报名", "我的积分", "设置"],
      headImg: require("@/assets/mine/headimg.png"),
      myTotal: 0,
      isDetails: false,
      orderNumber: "",
      isBacK: false,
      payUrl:''
    };
  },
  watch: {
    "$route.query.activeIndex": {
      handler(val) {
        if (val) {
          this.activeIndex = Number(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.asyncData();

    getInteTotal().then((res) => {
      if (res.code == 0) {
        this.myTotal = res.data;
      }
    });
  },
  methods: {
    asyncData() {
      getUserInfo().then((res) => {
        if (res.code == 0) {
          localStorage.setItem("isLogin", true);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$store.commit("setIsLogin", true);
          this.$store.commit("setUserInfo", res.data);
        }
      });
    },
    // 点击左侧nav
    goContent(index) {
      this.loading = true;
      this.activeIndex = index;
    },
    orderDetais(data) {
      console.log(data)
      this.orderNumber = data.orderNumber;
      this.payUrl = data.url
      this.activeIndex = 0;
      this.isDetails = true;
      
    },
    goBack() {
      this.isBacK = true;
      this.orderNumber = null;
      this.activeIndex = 0;
      this.isDetails = false;
    },
    // 编辑弹框
    eidt() {},
  },
};
</script>

<style lang="less" scoped>
.mine {
  background: #f5f8fa;
  padding-top: 22px;
  min-width: 1200px;
  .mine-content {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .mine-sidebar {
      width: 230px;
      background-color: #fff;
      // margin-right: 20px;
      margin-bottom: 20px;
      height: 700px;
      .personage-info {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        margin: 0 auto;
        flex-flow: column;
        .headImg-box {
          position: relative;
          .vipIdent {
            position: absolute;
            bottom: 20px;
            right: 0;
            z-index: 1;
            display: inline-block;
            width: 21px;
            height: 18px;
            border-radius: 0;
          }
        }
        .headImg {
          display: inline-block;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 10px;
        }
        .nickname {
          color: #333333;
          font-size: 14px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 210px;
          text-align: center;
        }
        .signature {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 210px;
          text-align: center;
          color: #999999;
          font-size: 12px;
          margin-top: 10px;
        }
        .addVip {
          width: 76px;
          height: 26px;
          line-height: 26px;
          background: linear-gradient(90deg, #f5deb7 0%, #ecc076 100%);
          border-radius: 13px 13px 13px 13px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #442203;
          margin-top: 10px;
          cursor: pointer;
        }
        .el-icon-edit {
          width: 16px;
          height: 15px;
          color: #728fae;
          margin-left: 5px;
          cursor: pointer;
        }
        .jftext {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          margin-top: 10px;
        }
      }
      .sidebar-list {
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          color: #333333;
          font-size: 16px;
          padding: 17px 0;
          cursor: pointer;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          // &:hover{
          //   color: #FB3A4E;
          // }
          .img-box {
            display: inline-block;
            width: 0px;
            height: 22px;
          }

          span {
            display: inline-block;
            width: 81px;
          }
        }
        .sidebar-active {
          position: relative;
          color: #fb3a4e;
          transition: all 0.1s;
          &::before {
            content: " ";
            display: inline-block;
            width: 4px;
            height: 25px;
            background: #fb3a4e;
            position: absolute;
            left: 0;
          }
          span {
            color: #fb3a4e;
          }
        }
      }
    }
    .min-right {
      width: 950px;
      background-color: #fff;
      padding: 0 30px;
      box-sizing: border-box;
      margin-bottom: 83px;
      min-height: 700px;
      position: relative;
      margin-left: 20px;
    }
    .min-right-a {
      padding: 0;
    }
  }
}
</style>
