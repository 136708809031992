<!--
 * @Author: your name
 * @Date: 2021-06-24 20:07:41
 * @LastEditTime: 2021-10-26 16:04:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\pages\mine\index\myorder.vue
-->
<template>
  <div class="jf-box">
    <div class="nav">
      <span>我的积分</span>
    </div>
    <div class="total">{{ myTotal }}积分</div>
    <div style="margin-top: 43px">
      <div v-if="jfList.length > 0">
        <div v-for="(item, index) in jfList" :key="index" class="list-item">
          <div>
            <span class="name">{{ getType(item.type) }}</span>
            <span class="num">+{{ item.score }}</span>
          </div>
          <div class="time">{{ item.createTime }}</div>
        </div>
      </div>
      <div class="mine-noda-box" v-if="jfList.length == 0">
        <NoData imgType="order"></NoData>
      </div>
    </div>
  </div>
</template>
<script>
import NoData from "@/components/no-data.vue";
import { getInteTotal, getInteList } from "@/api/mine.js";
export default {
  components: {
    NoData,
  },
  data: () => ({
    jfList: [],
    myTotal: 0,
    query: {
      page: 1,
      limit: 1000,
      total: 0,
    },
    typeText: "",
  }),
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getInteTotal().then((res) => {
        if (res.code == 0) {
          this.myTotal = res.data;
        }
      });
      this.getList();
    },
    getList() {
      getInteList(this.query).then((res) => {
        if (res) {
          console.log(res);
          this.jfList = res.data.list;
          this.query.total = res.data.total;
        }
      });
    },
    getType(type) {
      switch (type) {
        case 1:
          this.typeText = "注册";
          break;
        case 2:
          this.typeText = "成为会员";
          break;
        case 3:
          this.typeText = "报名兴趣班";
          break;
        case 4:
          this.typeText = "报名活动";
          break;
      }
      return this.typeText;
    },
  },
};
</script>
<style lang="less" scoped>
.jf-box {
  position: relative;
}
.nav {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f6f7f8;
  text-align: left;
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  position: relative;
  span::before {
    content: "";
    position: absolute;
    width: 64px;
    height: 2px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 2px solid #f5394c;
    bottom: 0;
  }
}
.list-item {
  text-align: left;
  margin-bottom: 25px;
  > div:first-child {
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-right: 20px;
  }
  .num {
    font-size: 20px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: #f5394c;
  }
  .time {
    margin-top: 12px;
    font-size: 12px;
    font-family: Arial-Regular, Arial;
    font-weight: 400;
    color: #999999;
  }
}
.total {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #f5394c;
}
</style>
