var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"type":'-2'}}),_c('div',{staticClass:"mine"},[_c('div',{staticClass:"mine-content"},[_c('div',{staticClass:"mine-sidebar"},[_c('div',{staticClass:"personage-info"},[_c('div',{staticClass:"headImg-box"},[_c('img',{staticClass:"headImg",attrs:{"src":_vm.$store.state.userInfo.avatar
                  ? _vm.$store.state.userInfo.avatar
                  : _vm.headImg,"alt":""}}),(_vm.$store.state.userInfo.isVip)?_c('img',{staticClass:"vipIdent",attrs:{"src":require("@/assets/mine/vipIdent.png")}}):_c('img',{staticClass:"vipIdent",attrs:{"src":require("@/assets/mine/novip.png")}})]),(_vm.$store.state.userInfo)?_c('div',{staticClass:"nickname"},[_vm._v(" "+_vm._s(_vm.$store.state.userInfo.username)),_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){_vm.activeIndex = 4}}})]):_vm._e(),(!_vm.$store.state.userInfo.isVip)?_c('div',{staticClass:"addVip",on:{"click":function($event){_vm.$refs.vipbox.dialogVisible = true}}},[_vm._v(" 加入会员 ")]):_vm._e(),(_vm.$store.state.userInfo)?_c('div',{staticClass:"jftext"},[_vm._v(" "+_vm._s(_vm.myTotal)+"积分 ")]):_vm._e()]),_c('div',{staticClass:"sidebar-list"},_vm._l((_vm.sidebarList),function(item,index){return _c('div',{key:index,class:_vm.activeIndex == index ? 'sidebar-active' : '',on:{"click":function($event){return _vm.goContent(index)}}},[_c('div',{staticClass:"img-box"}),_c('i',{class:index === 0
                  ? 'el-icon-s-order'
                  : index === 1
                  ? 'el-icon-coin'
                  : 'el-icon-setting'}),_c('span',[_vm._v(_vm._s(item))])])}),0)]),_c('div',{staticClass:"min-right"},[(_vm.activeIndex === 0 && !_vm.isDetails)?_c('MyOrder',{attrs:{"isBacK":_vm.isBacK},on:{"orderDetais":_vm.orderDetais}}):_vm._e(),(_vm.activeIndex === 1)?_c('MyJF'):_vm._e(),(_vm.activeIndex === 2)?_c('MySetting'):_vm._e(),(_vm.activeIndex === 4)?_c('MyInfo'):_vm._e(),(_vm.activeIndex === 0 && _vm.isDetails)?_c('orderdetails',{attrs:{"orderNumber":_vm.orderNumber,"payUrl":_vm.payUrl},on:{"goBack":_vm.goBack}}):_vm._e()],1)])]),_c('VipDaiolog',{ref:"vipbox"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }