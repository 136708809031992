<!--
 * @Author: your name
 * @Date: 2021-06-24 19:37:25
 * @LastEditTime: 2021-10-24 15:45:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\pages\mine\components\nav.vue
-->
<template>
  <div>
    <div class="mine-nav" v-if="navList.length > 0">
      <ul>
        <li
          :class="navActiveIndex == item.id ? 'li-active' : ''"
          v-for="(item, index) in navList"
          :key="index"
          @click="navClick(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    navType: {
      type: String,
      default: "",
    },
    navList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    navActiveIndex: -1,
  }),
  created() {
    // this.navActiveIndex = this.$route.query.activeIndex || -1;
  },
  methods: {
    navClick(id) {
      //   if (!(this.navType == "link" && index == 2)) {
      //
      //   }
      this.navActiveIndex = id;
      this.$emit("navClick", id);
    },
  },
};
</script>
<style lang="less" scoped>
.mine-nav {
  ul {
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid #f6f7f8;
    li {
      list-style: none;
      margin: 0 45px 0 15px;
      color: #333333;
      font-size: 16px;
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      transition: all 0.1s;
    }
    .li-active {
      border-bottom: 2px solid #fb3a4e;
      transition: all 0.1s;
    }
  }
}
</style>
